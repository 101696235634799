body{
  overflow: hidden;
}
.save-btn-spinner {
    display:inline-block;
    position:relative;
    width:10px;
    height:10px;
    left:-44px;
    top:-5px
    }


   .listening .save-btn-spinner {
      display:inline-block;
      position:relative;
      width:10px;
      height:10px;
      left:-36px !important;
      top:-6px !important
      }
    
    .login-bg {
    height:100vh;
    display:flex;
    justify-content:space-between;
    align-items:center
    }
    
    .new-login-btn {
    padding:8px 35px;
    font-size:15px;
    line-height:19px;
    font-weight:700;
    border-radius:2px;
    border:2px solid transparent;
    transition:.4s all ease
    }
    
    .add-d-flex {
    display:flex
    }
    
    .add-d-flex button {
    margin-right:1rem
    }
    
    .new-login-btn:hover {
    background:#0078d4;
    border:2px solid #0078d4;
    color:#fff!important;
    transition:.4s all ease
    }
    
    .login-left-side-wrapper {
    flex-grow:1;
    background-position:left center;
    background-repeat:no-repeat;
    background-size:cover;
    height:inherit;
    transition:.4s all ease-in-out
    }
    
    .signup-grow-change {
    width:480px;
    background-position:35% center;
    flex-grow:unset;
    transition:.4s all ease-in-out
    }
    
    .signup-width {
    flex-grow:1;
    width:auto!important;
    transition:.4s all ease-in-out
    }
    
    .login-left-side {
    display:flex;
    justify-content:space-between;
    flex-direction:column;
    position:relative;
    height:inherit
    }
    
    .login-left-side-wrapper .top-area {
    padding:34px 42px;
    height:inherit;
    position:absolute;
    width:100%
    }
    
    .login-left-side-wrapper .top-area .logo {
    cursor:pointer;
    width:fit-content
    }
    
    .login-left-side-wrapper .bottom-area {
    background:transparent linear-gradient(180deg,#00000000 0%,#141516 100%);
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    height:371px;
    position:absolute;
    width:100%;
    bottom:0
    }
    
    .login-left-side-wrapper .bottom-area .quotes-wrapper {
    position:absolute;
    bottom:0;
    padding:48px 36px
    }
    
    .login-left-side-wrapper .bottom-area .quotes-wrapper .quote {
    font-family:"Roboto",sans-serif;
    font-weight:300;
    font-style:italic;
    font-size:17px;
    line-height:1.5;
    color:#C5C5C5;
    margin-bottom:0
    }
    
    .login-left-side-wrapper .bottom-area .quotes-wrapper .author {
    margin-top:28px;
    font-family:"Roboto",sans-serif;
    font-weight:400;
    font-size:15px;
    line-height:1.5;
    letter-spacing:1.5px;
    color:#FFF;
    text-transform:uppercase;
    margin-bottom:0
    }
    
    .login-form {
    padding:31px 41px;
    width:700px;
    height:inherit;
    transition:ease-in-out .3s;
    background:#fff;
    display:flex;
    flex-direction:column;
    transition:.3s all ease-in-out
    }
    
    .login-form .other-page-wrapper {
    width:430px;
    margin:auto;
    flex-grow:1;
    display:flex;
    align-items:center;
    flex-direction:column;
    justify-content:center;
    gap:12px
    }
    
    .login-form .other-page-wrapper .img-wrapper {
    width:136px;
    height:136px;
    border-radius:50%;
    background-color:#e5f2fb;
    display:flex;
    align-items:center;
    justify-content:center;
    margin:15px 0
    }
    
    .login-form .other-page-wrapper h1 {
    font-family:"Roboto",sans-serif;
    font-weight:500;
    font-size:34px;
    line-height:1.5;
    color:#1D1F20;
    margin-bottom:0;
    text-align:center
    }
    
    .login-form .other-page-wrapper p {
    font-family:"Roboto",sans-serif;
    font-weight:400;
    font-size:16px;
    line-height:1.5;
    color:#7F7F7F;
    margin-bottom:0;
    text-align:center
    }
    
    .login-form .form-area-wrapper {
    width:430px;
    margin:auto;
    flex-grow:1;
    display:flex;
    align-items:center;
    gap:18px;
    flex-direction:column;
    justify-content:center
    }
    
    .login-form .form-area-wrapper h1.title {
    font-family:"Roboto",sans-serif;
    font-weight:500;
    font-size:34px;
    line-height:1.5;
    color:#1D1F20;
    margin-bottom:0;
    text-align:center
    }
    
    .login-form .form-area-wrapper.vendor-signup-form .sign-up-wrapper,.login-form .form-area-wrapper .sign-up-wrapper,.login-form .other-page-wrapper form,.login-form .form-area-wrapper form {
    width:100%
    }
    
    .ai-text-tabs-algin,ul.ai-textbox-features,.ai-product-text-tabs-algin {
    display:flex;
    flex-wrap:wrap;
    padding-left:0;
    margin-bottom:0;
    list-style:none;
    align-items:center
    }
    
    ul.ai-textbox-features li:first-child span {
    margin-right:11px
    }
    
    ul.ai-textbox-features li span {
    width:40px;
    height:40px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:50%;
    border:1px solid #d8d8d8;
    transition:.4s all ease
    }
    
    ul.ai-textbox-features li span:hover {
    background:#e9eaea;
    border:1px solid #e9e9e9;
    cursor:pointer
    }
    
    .ai-login-form-group {
    display:flex;
    flex-direction:column;
    width:100%
    }
    
    .ai-login-form-group label {
    color:#1D1F20;
    font-family:Roboto;
    font-style:normal;
    font-weight:500;
    font-size:14px;
    line-height:2.5
    }
    
    .ai-login-form-group input.ai-textbox {
    height:calc(1.5em + 0.75rem + 7px);
    border:1px solid #C0C8CE;
    border-radius:3px;
    color:#1D1F20;
    font-size:15px;
    line-height:24px;
    font-weight:500;
    padding:10px
    }
    
    .ai-login-form-group input.ai-textbox:focus {
    height:calc(1.5em + 0.75rem + 7px);
    border:2px solid #0078d4;
    color:#172b4d;
    background-color:#fff;
    outline:0;
    box-shadow:none
    }
    
    .pwd-visibility-btn {
    position:absolute;
    right:0;
    padding:5px 10px;
    cursor:pointer
    }
    
    .pwd-visibility-btn img {
    margin-bottom:0
    }
    
    .ai-login-form-group,.ai-contact-form-group {
    margin-bottom:16px;
    position:relative
    }
    
    .ai-login-submit-btn-base {
    width:100%
    }
    
    .ai-login-submit-btn {
    font-family:Roboto;
    font-style:normal;
    color:#fff;
    font-size:15px;
    line-height:1.4;
    font-weight:500;
    padding:9.5px 30px;
    background-color:#0078D4;
    border-radius:2px;
    border:none;
    width:100%
    }
    
    .ai-mt-2 {
    margin-top:2rem
    }
    .save-btn-spinner {
    display:inline-block;
    position:relative;
    width:10px;
    height:10px;
    left:-40px;
    top:-5px
    }
    
    .save-btn-spinner div {
    transform-origin:40px 11px;
    animation:save-btn-spinner 1.2s linear infinite
    }
    
    .save-btn-spinner div:after {
    content:" ";
    display:block;
    position:absolute;
    top:3px;
    left:37px;
    width:2px;
    height:4px;
    border-radius:45%;
    background:#fff
    }
    
    .save-btn-spinner div:nth-child(1) {
    transform:rotate(0deg);
    animation-delay:-1.1s
    }
    
    .save-btn-spinner div:nth-child(2) {
    transform:rotate(30deg);
    animation-delay:-1s
    }
    
    .save-btn-spinner div:nth-child(3) {
    transform:rotate(60deg);
    animation-delay:-.9s
    }
    
    .save-btn-spinner div:nth-child(4) {
    transform:rotate(90deg);
    animation-delay:-.8s
    }
    
    .save-btn-spinner div:nth-child(5) {
    transform:rotate(120deg);
    animation-delay:-.7s
    }
    
    .save-btn-spinner div:nth-child(6) {
    transform:rotate(150deg);
    animation-delay:-.6s
    }
    
    .save-btn-spinner div:nth-child(7) {
    transform:rotate(180deg);
    animation-delay:-.5s
    }
    
    .save-btn-spinner div:nth-child(8) {
    transform:rotate(210deg);
    animation-delay:-.4s
    }
    
    .save-btn-spinner div:nth-child(9) {
    transform:rotate(240deg);
    animation-delay:-.3s
    }
    
    .save-btn-spinner div:nth-child(10) {
    transform:rotate(270deg);
    animation-delay:-.2s
    }
    
    .save-btn-spinner div:nth-child(11) {
    transform:rotate(300deg);
    animation-delay:-.1s
    }
    
    .save-btn-spinner div:nth-child(12) {
    transform:rotate(330deg);
    animation-delay:0s
    }
    
    @keyframes save-btn-spinner {
    0% {
    opacity:1
    }
    
    100% {
    opacity:0
    }
    }
    
    .animation-logo {
    position:fixed;
    background:transparent;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index:1080;
    display:flex;
    align-items:center;
    justify-content:center
    }


    .segment-wrapper{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }

    .segment-view{
        display: flex;
        align-items: center;
        width: 100%;
    }

    .right-textarea{
        width: 100%;
        height: 100%;
        min-height: 200px;
        resize: none;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.5;
        color: #3c4043;
        overflow-x: hidden;
        overflow-y: auto;
        border: 1px solid #D9DBDF;
        border-radius: 5px;
        padding: 20px 17px;
    }

    .left-textarea{
        width: 100%;
        height: 100%;
        min-height: 200px;
        resize: none;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.5;
        color: #3c4043;
        overflow-x: hidden;
        overflow-y: auto;
        border: 2px solid #1976d2;
        border-radius: 5px;
        padding: 20px 17px;
    }

    .left-textarea:focus-visible{
        border: 2px solid #1976d2 !important;

    }

    .left-textarea:focus-within{
        outline: none;

    }

    .translate-button-for-segnment{
        background-color: #0078D4 !important;
        box-shadow: 0px 1px 3px #00000061;
        border-radius: 50% !important;
        text-transform: none !important;
        padding: 0 !important;
        border: none !important;
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
    }



    /* PromptForm */

.prompt-form{
    width: 100%;
    height: 100%;
    padding: 20px 0;
    /* max-width: 1320px; */
    margin-right: auto;
    margin-left: auto;

    padding-right: 50px;
    padding-left: 50px;
  }
  
  .button-prompt-form{
    padding: 5px ;
    border: none;
    background-color: #0071D4;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    margin-left: 5px;
    width: 75px;
  }
  
  .ml-0{
    margin-left: 0;
  }
  
  .prompt-fetchbox{
    margin-top: 35px;
  }
  
  .secondary-button-prompt-form{
    padding: 5px ;
    border: none;
    border-radius: 4px;
    background-color: transparent;
    width: 75px;
    height: 30px;
    transition: .3s ease all;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .secondary-button-prompt-form.delete{
    border: 1px solid #d11a2a;
    color: #d11a2a;

  }

  .secondary-button-prompt-form.edit{
    border: 1px solid #177245;
    color: #177245;

  }

  .secondary-button-prompt-form.visible{
    border: 1px solid #0071D4;
    color: #0071D4;

  }

  .secondary-button-prompt-form.visible:hover{
    background-color: #0071D4 !important;
    color: #FFFFFF;
    transition: .3s ease all;
  }

  .secondary-button-prompt-form.edit:hover{
    background-color: #177245 !important;
    color: #FFFFFF;
    transition: .3s ease all;
  }

  
  .secondary-button-prompt-form.delete:hover{
    background-color: #d11a2a !important;
    color: #FFFFFF;

    transition: .3s ease all;
  }

  .secondary-button-prompt-form:disabled{
    pointer-events: none;
  }

  .heading{
    margin-bottom: 20px !important;
    padding-bottom: 20px;
    border-bottom: 1px solid #C0C8CE;
  }

  h4{
    margin-bottom: 0 !important;
  }

  .prompt-drawer .MuiDrawer-paper{
    padding: 20px;
    width: 50%;
  }

  .add-header{
    margin-bottom: 20px;
  }

  .input-box-add{
    padding: 10px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #C0C8CE;
    resize: none;
  }

  .input-box-add:focus-visible{
    border: 1px solid #0071D4;
    outline: 1px solid #0071D4;
  }

  .prompt-drawer .MuiBox-root{
    width: 100%;

  } 

  .drawer-inner-wrap{
    width: 100%;
  }

  .submit-button{
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #0071D4;
    color:#ffffff ;
    margin-top: 10px;
    border-radius: 4px;

  }

  
 #triangle-down {
  display: block;
  width:0;
  height:0;
  border-left:5px solid transparent;
  border-right:5px solid transparent;
  border-top:5px solid #747474;
  }
  

  
  #triangle-up {
    display: block;
  width:0;
  height:0;
  border-left:5px solid transparent;
  border-right:5px solid transparent;
  border-bottom:5px solid #747474
  }
  
  .prompt-form label{
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 450;

  }

  .prompt-form h3{
    margin-bottom: 0;
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 600;
  }

  .drawer-inner-wrap h3{
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 600;

  }




  .prompt-form button{
    font-size: 14px;
    line-height: 1.4;
    font-weight: 500;
    font-family: 'Roboto';
    transition: .3s ease all;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
  }

  .button-prompt-form:hover{
    background-color: #0061bc !important;
    transition: .3s ease all;
  }

  .toast-box{
    height: auto;
    border-radius: 6px;
    background: #FFFFFF !important;
    display: flex;
    align-items: start;
    padding: 12px;
    justify-content: space-between;
  }
  
  .toast-box .content{
    color: #303030;
     /* font-family: 'Roboto'; */
    font-size: 14px;
    font-style: normal; 
    font-weight: 400;
    line-height: normal;
  }
  
  .toast-box .toast-icon-wrapper{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
  
  }
  
  .toast-box .toast-icon{
    width: 20px;
    height: 20px;
    color: #C2C2C2;
  }
  
  
  .toast-box.success{
  
    box-shadow: 0px -4px 0px 0px #34A853 inset;
  
  }
  
  .toast-box.error{
  
    box-shadow: 0px -4px 0px 0px #d32f2f inset;
  
  }
  
  .toast-box.warning{
  
    box-shadow: 0px -4px 0px 0px #FFCC00 inset;
  
  }
  
  .Toastify__toast{
    filter: drop-shadow(0px -1px 14px rgba(0, 0, 0, 0.14));
  }
  
  .Toastify__toast-body > div:last-child{
    box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.14);
  }
  
  .Toastify__toast-container{
    padding: 0 !important;
    
  }
  
  .Toastify__toast-body{
    padding: 0 !important;
  
  }
  
  .Toastify__toast-theme--light{
    background:transparent !important ;
    box-shadow: none !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    min-height: unset !important;
  } 
  
  .Toastify__close-button{
    display: none;
  }

  .prompts-list-each{
    padding: 20px 0;
    border-bottom: 1px solid #D9D9D9;
    border-radius: 8px;
    margin-bottom: 5px;
    gap:10px ;
    display: flex;
    align-items: start;
    justify-content: space-between;
  }

  .prompts-list-each div{
    color: #303030;
    /* font-family: 'Roboto'; */
   font-size: 16px;
   font-style: normal; 
   font-weight: 400;
   line-height: normal;
   padding-left: 5px;
   border-radius: 4px;
  }

  .prompts-list-each div.s-no{
   font-weight: 500;

  }

  .talktext::-webkit-scrollbar-track,.prompt-main-container::-webkit-scrollbar-track{
    background: transparent;
  }

  .talktext::-webkit-scrollbar-thumb,.prompt-main-container::-webkit-scrollbar-thumb{
  border-radius: 50px;
  border: 6px solid #C7C7C7;
  opacity: 1;
  }

  .talktext::-webkit-scrollbar,.prompt-main-container::-webkit-scrollbar {
    width: 8px!important;
}

.prompt-options{
  display: flex;
  align-items: center;
  gap: 5px;
}

.prompt-options span{
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.prompt-options span.edit:hover .edit-icon{
    color: #177245;
}

.prompt-options span.delete:hover .delete-icon{
  color: #d32f2f;
}

.prompt-options span.visible:hover .visible-icon{
  color: #0071D4;
}


.prompt-options .icons{
  color: #303030;
}

.result-container{
  border: 1px solid #D9D9D9;
  padding: 15px 5px 10px 15px ;
  border-radius: 8px;

}

.prompts-list-each{
    /* cursor: pointer; */
}

/* .prompts-list-each:hover span{
  color: #0071D4;

} */

/* .prompts-list-each:hover span{
  color: #0071D4;

} */

.prompts-list-each:hover span:focus-visible{
  outline: none;
}

.MuiTooltip-arrow {
  color:#2A2A2A!important
  }
  
  .MuiTooltip-tooltip {
  background-color:#2A2A2A!important
  }

  .bottom-navigation{
    display: flex;
    align-items: center;
    justify-content: center;      
    margin-top: 5px;
 
  }

  .regenerate-button{
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D9D9D9;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.3s ease all;
    margin-left: 10px;

  }

.regenerate-button:hover{
  background-color: #D9D9D9;
  transition: 0.3s ease all;
}

.navigation{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  padding: 2px;
}

.navigation-button{
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.potition{
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-button:hover{
  background-color: #D9D9D9;
  transition: 0.3s ease all;
}

.active-prompt{
  outline: 2px solid #0071D4 !important;
}

.highlighted-heading{
  color: #0071D4 !important;
}


.prompts-list-each div:focus-visible{
  outline-color: #0071D4;
}

.prompt-options-button{
  border: none;
  padding: 5px;
  background-color: #0071D4;
  color: #FFFFFF;
  font-size: 16px;
  font-style: normal; 
  font-weight: 400;
  border-radius: 4px;
}




/* Ailaysa Meet */

.meet-stream{
  position: relative;
}

.meet-chat{
  min-width:  500px;
  background-color: transparent;
  padding: 20px;
  height: 100%;
}

.meet-stream{
  width: 100%;
}

.meet-main-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.divider{
  min-width: 1px;
  height: 100%;
  background-color: #C7C7C7;
}

.chat-inner-wrap{
  border-radius: 24px;
  border: 1px solid #D9D9D9;
  height: 100%;
}

.meet-start-box{
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #D9D9D9;
  gap: 10px;
}

.meet-start-box h1{
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

}

.chat-list{
  height: calc(100vh - 250px);
  padding: 15px;
  overflow: auto;
}

.chat-box{
  width: 100%;
  padding: 10px;
}

.chat-box input{
  width: 100%;
  height: 50px;
  border-radius: 16px;
  padding: 5px;
  border: 1px solid #9AA0A6;
}

.meet-total-wrapper{
  display: flex;
  flex-direction: column;
}

.custom-input-box.MuiTextField-root{
  /* width: 100%; */
  width: 350px;
} 

.custom-input-box .MuiFilledInput-root{
  background-color: #F7F7F7;
}

.custom-input-box .MuiInputBase-input{
  color: #303030;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.custom-input-box .MuiInputLabel-root{
  color: #929292 !important;
}

.custom-input-box.dark .MuiFilledInput-root{
  background-color: #ffffff17 ;
}
.custom-input-box.dark .MuiFilledInput-root:hover{
  background-color: rgba(255, 255, 255, 0.13) ;
}

.custom-input-box .MuiInputLabel-root.Mui-focused{
  color: #0073DF !important;
}

.custom-input-box.dark .MuiInputBase-input{
  color: #FFFFFF !important;

}

.custom-input-box .MuiFilledInput-root::before{
  border-bottom: 1px solid #D9D9D9;
}

.custom-input-box .Mui-error::before{
  border-bottom: 1px solid #d32f2f !important;
}

.custom-input-box .MuiFormLabel-asterisk {
  color: #d32f2f;

}

.custom-input-box .MuiFilledInput-root::after{
  border-bottom: 2px solid #0073DF;
}



.custom-input-box .MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before{
  border-bottom: 1px solid #9AA0A6;

}

.main-btn {
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 0px;
  padding: 10px 22px;
  background-color: #0078D4;
  border-radius: 2px;
}

.question-section {
  margin-bottom: 20px;
}

.main-q-and-a-section {
  display: flex;
  align-items: start;
  flex-direction: row;
}

.avatar-outer-ring {
  min-width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px #00000009;
  position: relative;
}

.avatar-icon {
  margin-right: 6px;
}

.q-and-a-section {
  display: flex;
  align-items: start;
  flex-direction: column;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.name-wrapper {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.answer-wrapper {
  font-size: 16px;
  white-space: pre-line;
  font-weight: 400;
}

.primary-text-color {
  color: #000;
}

.ailays-wp-avatar-bg-blue-color {
  background: #0074d3;
  /* cursor: pointer; */
}

.ailays-wp-avatar-bg-light-blue-color {
  background: #F0F8FF;
  /* cursor: pointer; */
}

.ailays-wp-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-family: 'Roboto',sans-serif!important; */
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}

.img-align-radius {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  object-fit: cover;
}

.end-call{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #d11a2a;
  border-radius: 50%;
  cursor: pointer;
}

.meet-screen-wrapper{
  display: flex;
  align-items: center;
  flex-direction: row;
}

.meet-screen-outer-wrap{
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.elarning-wrap{
  /* display: flex;
  align-items: center;
  justify-content: center; */
padding-top: 25px;
}

.overlay{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  position: absolute;
  z-index: 5;
}

.first-half{

}

.divider{

}

.start-learning-button{
  background-color: #0074d3;
  width: auto;
  box-shadow: none;
  border-radius: 3px;
  text-transform: none;
  padding: 0;
  border: none;
  padding: 10px 25px;
  color: #fff;
  font-family: 'Roboto';
  transition: 0.3s ease all;
}

.start-learning-button:hover {
  background-color: #0265b1;
  box-shadow: none;
  transition: 0.3s ease all;
}

.chat-conversation{
  display: flex;
  align-items: center;
}

.chat-conversation{
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.chat-conversation .bot-conversation{
  width: 60%;
  padding: 15px;
  display: flex;
  align-items: start;
  /* justify-content: end; */
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #76FFFF;
  background: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(10px);
  color: #FFF;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.user-conversation{
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  align-items: start;
  gap: 10px;
}

.user-icons{
  background-color: #FFFFFF;
  border-radius: 4px;
  min-width : 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.user-icons .icon{
  width: 50px;
  height: 50px;
}

.play-pause-button{
  width: 32px;
  height: 32px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;

}

.ask-question{
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 15px;
  border: none;
  border-radius: 8px;

}

.mic {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px  ;
  background-color: #0073DF;
  color: #fff;
  font-size: 33px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.25s;
  box-shadow: 0px 4px 4px rgba(0, 115, 223, 0.18);
}

.mic::before {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background-color: inherit;
  z-index: -1;
}

.listening {
  background-color: #0da9d9;
}
.listening::before {
  animation: listening 1.3s infinite;
}

@keyframes listening {
  from {
    opacity: 0.25;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}

.shool-background{
  background: url('../icons/class.png');
  background-position: center;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: cover; */
  height: 100vh;
  backdrop-filter: blur(2px);
 
}

.shool-background::before {
  content: ""; /* Important */
  z-index: -1; /* Important */
  position: inherit;
  left: inherit;
  top: inherit;
  width: inherit;
  height: inherit;
  background-image: inherit;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  filter: blur(2px);
}

.elarning-wrap{
  height: calc(100vh - 183px);
  margin-top: 20px !important;
 
}

.shool-background .container-fluid{
  /* filter: blur(2px); */
}

.teacher-section{
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: start;
}

.student-section{
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: end;
}

.teacher{
  min-width: 50%;
}

.teacher img {
  width: 100%;
}

.student{
  max-width: 50% !important;
  position: relative;
}

.student img {
  width: 100%;
}

.chat-mic{
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
}

.student-chat{
  min-width: 50%;
  min-height: 62px;
  max-width: 100%;
  color: #FFF;
  font-family: 'Roboto';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4; 
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.40);
  border-radius:12px;
  animation: message 0.30s ease-out 0s forwards;
  animation-delay: 300ms;
  padding-top: 0;
  transform: scale(0);
  max-height: 0;
  transform-origin: 100% 100%;

}


.teacher-chat{
  min-width: 50%;
  min-height: 62px;
  max-width: 100%;
  color: #FFF;
  font-family: 'Roboto';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4; 
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.40);
  border-radius:12px;
  animation: message 0.30s ease-out 0s forwards;
  animation-delay: 300ms;
  transform-origin: 0 100%;
  padding-top: 0;
  transform: scale(0);
  max-height: 0;
  transition-timing-function: ease;
}

@keyframes message {
  0% {
    max-height: 100vmax;
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    max-height: 100vmax;
    overflow: visible;
    padding-top: 1rem;
  }
}

.tri-right.left-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -44px;
  transform: rotate(90deg);
	right: auto;
  top: 15px;
	bottom: auto;
	border: 22px solid;
	border-color: rgba(0, 0, 0, 0.40) transparent transparent transparent;
}

/* CSS talk bubble */
.talk-bubble {
	/* margin: 40px; */
  display: inline-block;
  position: relative;
	/* width: 200px; */
	height: auto;
  
}
.border{
  border: 8px solid #666;
}
.round{
  border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;

}

.tri-right.right-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -40px;
  top: 10px;
  transform: rotate(-90deg);

	bottom: auto;
	border: 20px solid;
  border-color: rgba(0, 0, 0, 0.40) transparent transparent transparent;

}

.chat-mic {
  position: absolute;
  width: 100%;
  bottom: 150px;
  border-radius: 100px;
  border: 1.4px solid rgba(255, 255, 255, 0.40);

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), rgba(128, 128, 128, 0.30);
  padding: 12px 0;
  background-blend-mode: normal, luminosity;
  /* Blur */
  backdrop-filter: blur(50px);
  cursor: pointer;
}

.chat-mic .listening{
  background-color: #0073DF;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.chat-mic .listening .icon{
  color: #FFFFFF;
}



@keyframes quiet {
  25%{
    transform: scaleY(.6);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(.8);
  }
}

@keyframes normal {
  25%{
    transform: scaleY(1);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(.6);
  }
}
@keyframes loud {
  25%{
    transform: scaleY(1);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(1.2);
  }
}

.boxContainer{
  display: flex;
  justify-content: space-between;
  height: 50px;
  --boxSize: 8px;
  --gutter: 4px;
  width: calc((var(--boxSize) + var(--gutter)) * 5);
}

.box{
  transform: scaleY(.4);
  height: 100%;
  width: var(--boxSize);
  background: #0073DF;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 8px;
}

.box1{
  animation-name: quiet;
}

.box2{
  animation-name: normal;
}

.box3{
  animation-name: quiet;
}

.box4{
  animation-name: loud;
}

.box5{
  animation-name: quiet;
}



.shool-background .lab-main-navbar{
  border-bottom: none;
  background-color: transparent;
}

.shool-background .lab-main-navbar .container{
  max-width: 95%;
 
  margin: 0 auto;
  border-radius: 0 0 12px 12px;
  border-image:  linear-gradient(105deg, rgba(118, 255, 255, 0.40) 3.09%, rgba(118, 255, 255, 0.00) 73.27%);
  border-width: 1px;
  border-style: solid;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(40px);

}

.speak-icon{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -50px;
  left: 0;
  cursor: pointer;
}


body{
  height: 100vh;
  overflow: auto;
}

.talktext{
  max-height: 350px !important;
  overflow-y: auto;
}

.main-speach-wrapper{
  gap: 20px !important;
}

@media only screen and (max-width: 1024px) {
    .main-speach-wrapper{
      flex-direction: column;
    }

    .teacher-section{
      height: 100%;
      overflow: hidden;
    }

    .student-section{
      height: 100%;
      overflow: hidden;
    }

    .teacher-section{
      border-bottom: 1px solid rgb(0 0 0 / 12%);
    }

    .student-chat{
      max-width: 50%;
    }

    .chat-mic{
      bottom: 250px  !important;
    }
  
}


@media only screen and (max-width: 754px) {
  .main-speach-wrapper {
   padding: 0 !important;
  }

   .teacher-section{
    flex-direction: column-reverse !important;
  }

  .student-section{
    flex-direction: column !important;

  }

  .teacher-chat{
    font-size: 12px !important;
    padding: 10px !important; 
  }

  .student{
    max-width: 40% !important;
  }

  .chat-mic .listening{
    width: 30px !important;
    height: 30px !important;
  }

  .chat-mic {
    padding: 4px !important;
  }

  .tri-right.left-top:after{
    content: none !important;
  }

  .main-speach-wrapper{
    height: calc(100vh - 100px) !important;
  }

  .main-speach-wrapper{
    flex-direction: column;
  }

  .teacher-section{
    flex-direction: row !important;
  }

  .student-section{
    flex-direction: row !important;
  }

  .teacher{
    min-width: 40% !important;
    max-width: 40% !important;
  }

  .student-chat{
    font-size: 12px !important;
    padding: 10px !important; 
  }

  .tri-right.right-top:after{
    content: none !important;
  }

  .boxContainer{
    height: 30px !important;
  }

  .login-form .form-area-wrapper{
    padding: 0 !important;
    width: 100% !important;

  }

  .teacher-conversation-wrapper{
    min-width: 60% !important;
    margin-top: 50px !important;
  }

  .teacher-section{
    border-bottom: 1px solid rgb(0 0 0 / 12%);
  }

  .talktext{
    max-height: 200px !important;
    overflow-y: auto;
  }

  .teacher-section {
    align-items: start !important;  
  }

  .main-speach-wrapper {
    gap: 20px !important;
  }

  .student-section{
    height: 100%;
    overflow: hidden;
  }

  .student img{
    width: 125% !important;
  }


  .student-chat{
    min-width: 60% !important;
    max-width: 60% !important;


  }

  .chat-mic{
    left: 5px !important;
    width: 95% !important;
    bottom: 80px !important;
  }

}


.student-data-input-box{
  padding: 25px 25px 45px 25px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

}

.student-name{
  margin-bottom: 10px;
}

.student-data-input-box button{
  width: 100%;
  box-shadow: none;
  border-radius: 4px !important;
  background-color: #0078D4 !important;
  border: none;
  padding: 10px;
  margin-top: 20px;
  font-style: normal;
  color: #ffffff;
  font-size: 15px;
  line-height: 1.4;
  font-weight: 500;
  width: 100%;
  text-transform: capitalize;
}

.student-data-input-box button:hover{

  background-color: #0265b1 !important;

}

.student-name input{
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0;
  border: 1px solid #C0C8CE;
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  min-width: 200px;
  width: 100%;
  padding: 0;
  height: 44px;
  padding: 2px 8px;
}

.ai-mark-confirm-box .rodal-dialog{
  height: 470px  !important;
}

.welcome{
  font-size: 42px;
  font-weight: bold;
  font-style: normal;
  color: #000000;
  text-decoration: none;
  margin-bottom: 0;
  text-align: center;
}

.welcome-image{
  width: 100%;
  height: 150px;
  text-align: center;
}

.welcome-image img{
  /* width: 100%; */
  height: 100%;
  /* margin-bottom: 20px; */
  border-radius: 8px;


}

.student-name input::placeholder{
  color: #A9A9A9 !important;

}

.language-button__placeholder {
  color: #A9A9A9 !important;
}

@media only screen and (max-width: 754px) {

  .ai-mark-confirm-box .rodal-dialog{
    width: calc(100% - 10px) !important;

  }

}

.jumping-dots-loader {
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;
  margin: 0 auto;
}

.jumping-dots-loader span {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 1);
  margin: 5px 0px;
}

.jumping-dots-loader span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}

.jumping-dots-loader span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}

.jumping-dots-loader span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}

@keyframes bounce {
  0%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}


.digitalization-initial-wrap{
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
  height: calc(100vh - 120px);
  padding: 0 20px;
  position: relative;

}

[contenteditable=true]:empty:before{
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
}

.digitalization-main-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  border: 1px solid #C0C8CE;

}

.digitalization-content-wrapper{
  height: 100%;
  width: 100%;
  padding: 5px 10px;
} 

.content-editable-div{
  /* height: 100%; */
  height: calc(100vh - 200px);
  scrollbar-width: thin;
  width: 100%;
  border-radius: 4px;
  border: 1px solid none;
}

.digitalization-options{
  height: 80px;
}

.digitalization-options{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.digitalization-options button{
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 0px;
  padding: 10px 22px;
  background-color: #0078D4;
  border-radius: 4px;
  border: none;

}

.content-editable-div{
  padding: 20px;
  overflow-y: auto;
  font-family: "Noto Sans Tamil", sans-serif;
}

.content-editable-div:focus-visible{
  /* border: 1px solid #0071D4;
  outline: 1px solid #0071D4; */
  outline: none;
}

.mistake{
  background-color: red;
  color: #FFFFFF;
}

.update-button {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #0078d4 !important;
  box-shadow: 0 1px 3px #00000061;
  border-radius: 50% !important;
  text-transform: none !important;
  padding: 0 !important;
  border: none !important;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  z-index: 1;
  border: none;
}