/* html{
    overflow: hidden;
  } */
  

  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  
  #navbar > a {
    float: left;
    display: block;
    color: #000;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }
  
  .navbar-display-logo {
    padding: 5px 14px !important;
  }
  
  .ail-workspace-blue-nav {
    background: #0074d3 !important;
  }
  
  .nav-transeditor-wrapper {
    display: flex;
    align-items: center;
    margin-left: 47px;
    gap: 44px;
  }
  
  .nav-transeditor-wrapper .navbar-display-show {
    padding: 0px !important;
  }
  
  /* .padd{
    padding: 66px 0 0 0;
  } */
  
  .sidebar-active-area-wrapper {
    background-color: #ffffff !important;
    width: 361px;
    border-right: 2px solid #e4e4e4;
    overflow-y: auto !important;
    height: 100vh !important;
  }
  
  
  .maindiv{

    width: 100%;
    height: 100%;
  }


  .ai-working-col-wrapper {
    flex-grow: 1;
    /* overflow: auto; */
    background-color: #f3f2f2;
    padding:  40px 0 0 0 !important;
    /* height: 1000px !important; */
    height:auto;
  
  }
  
  .canvas1{
    background-color: #FFFFFF;
    position: relative;
    height: 600px;
    width: 800px;
    margin: 0 auto;
    box-shadow: 2px 2px 12px 2px rgba(70, 70, 70, 0.5);
  }
  
  .can{
    box-shadow: 2px 2px 5px 2px rgba(70, 70, 70, 0.15);
  }

  .canvas-container {
     margin:0 auto ;
    }


  .canvas2{
    background-color: #FFFFFF;
    position: relative;
    height: 768px;
    width: 1024px;
    margin: 0 auto;
    box-shadow: 2px 2px 12px 2px rgba(70, 70, 70, 0.5);
  }
  
  .canvas3{
    background-color: #FFFFFF;
    position: relative;
    height: 720px;
    width: 1280px;
    margin: 0 auto;
    box-shadow: 2px 2px 12px 2px rgba(70, 70, 70, 0.5);
  }
  
  .canvas4a{
    background-color: #FFFFFF;
    position: relative;
    height: 480px;
    width: 480px;
    margin: 0 auto;
    box-shadow: 2px 2px 12px 2px rgba(70, 70, 70, 0.5);
  }
  
  .canvas4b{
    background-color: #FFFFFF;
    position: relative;
    height: 480px;
    width: 480px;
    margin: 0 auto;
    box-shadow: 2px 2px 12px 2px rgba(70, 70, 70, 0.5);
  }
  
  .canvas-flex{
  
    display: flex;
    align-items: center;
  
  }
  
  .noLRMar{
    margin-right: 0 !important;
    margin-left: 0 !important;
   }
  
   .canvas-logo{
    margin-bottom: 3px;
    font-size: 18px;
    font-weight:500;
    color: #FFFFFF;
  
   }
  
   .logo-flex{
    display: flex;
    align-items: center;
   }
  
   .size-img{
  
    width: 100%;
    height: 100%;
    transition: 0.3s ease all;
  
   }
  
   .size-img:hover{
  
    transform: scale(1.1);
    transition: 0.3s ease all;
    cursor: pointer;
   }
   
  
   .but-flex{
  
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px 0 35px 0;
   }
  
   .gap{
  
    gap: 25px;
    padding-bottom: 70px;
    height: auto;
  
   }
  
   .transgenerated-image{
      width: 100%;
      height: 100%;
  
   }
  
   .but:hover{
      color: #fff !important;
      background-color: #0b5ed7 !important;
      border-color: #0a58ca !important;
      transition: 0.3s ease all;
  }
  
  .but{
      color: #0d6efd !important;
      background-color: #fff !important;
      border-color: #0d6efd !important;
      transition: 0.3s ease all;
  
  }
  
  :disabled {
    opacity: 0.7;
  }
  
  .callout {
  
    width: 100%;
    height: auto;
    /* background-color: #ffffff; */
    text-align: center;
    animation: flicker 1.5s infinite alternate;
    color: #0d6efd;
    font-weight: bold;
  }
  
  @keyframes flicker {
      
    0%, 18%, 22%, 25%, 53%, 57%, 100% {
  
        text-shadow:
        0 0 4px #fff,
        0 0 11px #fff,
        0 0 19px #fff,
        0 0 40px #0fa,
        0 0 80px #0fa,
        0 0 90px #0fa,
        0 0 100px #0fa,
        0 0 150px #0fa;
    
    }
    
    20%, 24%, 55% {        
        text-shadow: none;
    }    
  }
  
  .marNone{
    margin-top: 10px;
    width: 100% !important;
  }
  
  .sidebar-active-area-wrapper{
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  
  .sidebar-active-area-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }
  
  .sidebar-active-area-wrapper::-webkit-scrollbar {
    width: 6px !important;
    background-color: transparent;
  }
  
  .sidebar-active-area-wrapper::-webkit-scrollbar-thumb {
    background-color: #acacac;
  }
  
  .accordion-body {
    padding: 0.5rem 1.25rem !important;
  }
  
  .color-ele-height{
  
    height: calc(3rem + 2px) !important;
  }
  
  .margin{
  
    margin: 0 60px !important;
  
  }
  
  .canvas8{
    background-color: #FFFFFF;
    position: relative;
    height: 500px;
    width: 500px;
    margin: 0 auto;
    box-shadow: 2px 2px 12px 2px rgba(70, 70, 70, 0.5);
  }
  
  .canvas9{
    background-color: #FFFFFF;
    position: relative;
    height: 500px;
    width: 500px;
    margin: 0 auto;
    box-shadow: 2px 2px 12px 2px rgba(70, 70, 70, 0.5);
  }
  
  .txt1-style{
    cursor: pointer;
    font-size: 24px;
    line-height: 41px;
    font-weight: 600;
    color: #3a71c9;
    text-shadow: 5px 5px 5px #d15260 ;
  
  }
  
  .txt2-style{
    
    cursor: pointer;
    font-size: 24px;
    line-height: 41px;
    font-weight: 600;
    color: #96f288;
    text-shadow: 5px 5px 5px #d15260 ;
  
  }
  
  .txt3-style{
  
    cursor: pointer;
    font-size: 24px;
    line-height: 41px;
    font-weight: 600;
    color: #5bb5ae;
    text-shadow: 6px 6px 0px #a4b3a8 ;
  
  }
  
  .txt4-style{
  
    cursor: pointer;
    font-size: 24px;
    line-height: 41px;
    font-weight: 600;
    color: #ded66a;
    text-shadow:1px 1px 2px red;
  }


 