* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;

    body {
        margin: 0;
        padding: 0;

        font: {
            family: "Inter", sans-serif !important;
        }
    }
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-left: 0px;
    margin-bottom: 0px;
}

input {
    &:focus {
        outline: none;
    }

    &[type="number"] {

        &::-webkit-inner-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
        }
    }
}


// Header Styles

.lab-main-navbar {
    height: 63px;
    border-bottom: 1px solid #DADCE0;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1080;
    background-color: transparent;

    .main-nav-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        .main-btn {
            font: {
                size: 15px;
                weight: 500;
                style: normal;
            }

            line-height: 1.5;
            color: #ffffff;
            text-decoration: none;
            margin-bottom: 0px;
            padding: 10px 22px;
            background-color: #0078D4;
            border-radius: 2px;
        }
    }
}

// Header Styles

// Home Styles

.home-section-main-wrapper {
    .home-section-wrap {
        padding: 40px 0px 90px 0px;

        h1 {
            font: {
                size: 42px;
                weight: bold;
                style: normal;
            }

            color: #000000;
            text-decoration: none;
            margin-bottom: 35px;
            text-align: center;
        }

        p {
            font: {
                size: 18px;
                weight: normal;
                style: normal;
            }

            line-height: 1.7;
            color: #4E4E4E;
            text-decoration: none;
            margin-bottom: 0px;
            text-align: center;
        }

        .home-lab-services-row {
            margin-top: 50px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 40px 30px;

            .tile-box-lists {
                width: 412px;
                height: 241px;
                box-shadow: 0px 1px 1px #BFC5CF;
                border: 1px solid #E6E9EF;
                border-radius: 5px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                gap: 28px;
                transition: 0.3s all ease;
                cursor: pointer;

                &.lock-wrapper{
                    cursor: context-menu !important;
                    position: relative;
                    &:hover{
                        .lock-area{
                            opacity: 1;
                            visibility: visible;
                            transition: 0.4s all ease;
                        }
                    }
                }

                .lock-area{
                    position: absolute;
                    background-color: #ffffff9e;
                    backdrop-filter: blur(1px);
                    -webkit-backdrop-filter: blur(1px);
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.4s all ease;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .lock-icon{
                        font-size: 100px;
                        color: #0078D4;
                    }
                }

                p {
                    font: {
                        size: 20px;
                        weight: 500;
                        style: normal;
                    }

                    line-height: 1.5;
                    color: #000000;
                    text-decoration: none;
                    margin-bottom: 0px;
                    text-align: center;
                }

                &:hover {
                    box-shadow: 0px 8px 16px #b1b9c57a;
                    border: 1px solid transparent;
                    transition: 0.3s all ease;
                }
            }
        }
    }
}

// Home Styles


// Social Media Localization Styles

.sml-section-wrapper {

    .not-found-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        height: 400px;

        .back-to-home {
            background-color: #0078D4;
            padding: 10px 30px;

            font: {
                size: 15px;
                weight: 500;
                style: normal;
            }

            line-height: 20px;
            color: #ffffff;
            border-radius: 2px;
            &:disabled{
                opacity: 0.5;
            }
        }

        h1 {
            font: {
                size: 34px;
                weight: bold;
                style: normal;
            }

            color: #000000;
            text-decoration: none;
            margin-bottom: 24px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .back-icon{
                position: absolute;
                left: -60px;
                top: -4px;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: 0.3s all ease;
                &:hover{
                    background-color: #ebebeb;
                    cursor: pointer;
                    transition: 0.3s all ease;
                }
                .arrow-icon{
                    font-size: 23px;
                    color: #000000;
                }
            }
        }
    }
    .sml-wrap {
        padding: 60px;
        &.trans-gen-wrap{
            padding: 35px;
            h1{
                .back-icon{
                    left: -35px;
                }
            }
        }

        h1 {
            font: {
                size: 34px;
                weight: bold;
                style: normal;
            }

            color: #000000;
            text-decoration: none;
            margin-bottom: 24px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .back-icon{
                position: absolute;
                left: -60px;
                top: -4px;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: 0.3s all ease;
                &:hover{
                    background-color: #ebebeb;
                    cursor: pointer;
                    transition: 0.3s all ease;
                }
                .arrow-icon{
                    font-size: 23px;
                    color: #000000;
                }
            }
        }

        p {
            font: {
                size: 16px;
                weight: normal;
                style: normal;
            }

            line-height: 1.7;
            color: #4E4E4E;
            text-decoration: none;
            margin-bottom: 0px;
            text-align: center;
        }

        .sml-link-localization-wrap {
            padding-top: 98px;

            ul.sml-tab-links {
                display: flex;
                align-items: center;
                gap: 20px;
                padding-left: 0px;
                margin-bottom: 0px;
                border-bottom: 1px solid #DFDFDF;

                li.sml-tab-link-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 7.5px;
                    padding: 13px 14px;
                    font: {
                        size: 16px;
                        weight: normal;
                        style: normal;
                    }

                    line-height: 1.5;
                    color: #3C4043;
                    background-color: transparent;
                    border-radius: 4px 4px 0px 0px;
                    transition: 0.3s all ease;

                    &:hover {
                        background-color: #ebebeb;
                        transition: 0.3s all ease;
                        cursor: pointer;
                    }
                    &:nth-child(3){
                        img{
                            width: 24.47px;
                            height: 24.47px;
                        }
                    }

                    &.active {
                        background-color: #E8F0FE;
                        color: #1D9BF0;

                        font: {
                            weight: 500,
                        }

                        transition: 0.3s all ease;
                        cursor: pointer;
                        position: relative;

                        &::after {
                            content: "";
                            height: 3px;
                            width: 100%;
                            background-color: #1D9BF0;
                            position: absolute;
                            left: 0;
                            bottom: -2px;
                        }
                    }
                }
            }

            .sml-localization-tab {
                padding: 54px 0px;

                .social-media-localization-area {
                    .social-media-url-area {
                        height: 50px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        margin-bottom: 70px;

                        input {
                            border-style: solid;
                            border-width: 2px 0px 2px 2px;
                            border-color: #C0C8CE;
                            border-radius: 3px 0px 0px 3px;
                            height: inherit;
                            flex-grow: 1;
                            padding: 15px 21px;

                            font: {
                                size: 16px;
                                weight: normal;
                                style: normal;
                            }

                            line-height: 1.5;
                            color: #3C4043;

                            &::placeholder {
                                font: {
                                    size: 16px;
                                    weight: normal;
                                    style: normal;
                                }

                                line-height: 1.5;
                                color: #9B9FA2;
                            }
                        }

                        .url-search-btn {
                            width: 50px;
                            height: inherit;
                            background-color: #0078D4;
                            border-radius: 0px 2px 2px 0px;

                            .arrow-icon {
                                font-size: 30px;
                                color: #ffffff;
                                transform: rotate(180deg);
                            }

                            &:disabled{
                                opacity: 0.5;
                            }
                        }
                    }

                    .social-media-original-txt-area {
                        h3 {
                            font: {
                                size: 20px;
                                weight: bold;
                                style: normal;
                            }

                            color: #000000;
                            text-decoration: none;
                            margin-bottom: 18px;
                        }

                        .original-text-area-wrap {
                            padding: 36px 32px 21px 32px;
                            box-shadow: 0px 1px 1px #B1B9C5;
                            border: 1px solid #DCE0E6;
                            border-radius: 5px;
                            background-color: #ffffff;

                            .text-area-form {
                                height: auto;
                                min-height: 120px;
                                max-height: 100%;
                                border: 0px;
                                width: 100%;
                                padding-right: 10px;
                                resize: none;
                                text-align: left;

                                font: {
                                    size: 16px;
                                    weight: normal;
                                    style: normal;
                                }

                                line-height: 1.5;
                                color: #3C4043;

                                &::placeholder {
                                    font: {
                                        size: 16px;
                                        weight: normal;
                                        style: normal;
                                    }

                                    line-height: 1.5;
                                    color: #9B9FA2;
                                }

                                &:focus {
                                    outline: none;
                                }

                                &:hover::-webkit-scrollbar-thumb {
                                    opacity: 1;
                                    visibility: visible;
                                    transition: 0.3s all ease;
                                }

                                &::-webkit-scrollbar-track {
                                    border-radius: 30px;
                                    background-color: transparent;
                                    transition: 0.3s all ease;
                                }

                                &::-webkit-scrollbar {
                                    width: 5px;
                                    background-color: transparent;
                                    transition: 0.3s all ease;
                                }

                                &::-webkit-scrollbar-thumb {
                                    opacity: 0;
                                    visibility: hidden;
                                    border-radius: 30px;
                                    background-color: #C8C6C4;
                                    transition: 0.3s all ease;
                                }
                            }

                            .bottom-btn-wrap {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;

                                .lang-select-wrap {
                                    display: flex;
                                    align-items: center;
                                    gap: 15px;

                                    .select-lang__indicator,
                                    .select-src-lang__indicator{
                                        color: #000000;
                                    }

                                    .lang-select-btn {
                                        background-color: transparent;
                                        padding: 10px 13px 10px 13px;
                                        display: flex;
                                        align-items: center;
                                        border-radius: 2px;
                                        gap: 7px;
                                        transition: 0.3s all ease;

                                        .txt {
                                            font: {
                                                size: 15px;
                                                weight: 500;
                                                style: normal;
                                            }

                                            line-height: 20px;
                                            color: #3C4043;
                                        }

                                        .icon {
                                            color: #000000;
                                        }

                                        &:hover {
                                            background-color: #EBEBEB;
                                            transition: 0.3s all ease;
                                            cursor: pointer;
                                        }
                                    }
                                }

                                .translate-btn {
                                    background-color: #0078D4;
                                    padding: 10px 30px;

                                    font: {
                                        size: 15px;
                                        weight: 500;
                                        style: normal;
                                    }

                                    line-height: 20px;
                                    color: #ffffff;
                                    border-radius: 2px;
                                    &:disabled{
                                        opacity: 0.5;
                                    }
                                }
                            }
                        }
                    }

                    .social-media-translated-txt-area {
                        margin-top: 55px;

                        h3 {
                            font: {
                                size: 20px;
                                weight: bold;
                                style: normal;
                            }

                            color: #000000;
                            text-decoration: none;
                            margin-bottom: 18px;
                        }

                        .translated-area-row {
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            gap: 30px;

                            .translated-area-box {
                                width: 573px;
                                height: 375px;
                                box-shadow: 0px 1px 1px #B1B9C5;
                                border: 1px solid #DCE0E6;
                                border-radius: 5px;
                                display: flex;
                                flex-direction: column;
                                transition: 0.3s all ease;

                                .header {
                                    padding: 22px 36px 16px 36px;
                                    border-bottom: 1px solid #DCE0E6;
                                    height: 60px;

                                    p {
                                        text-align: left;

                                        font: {
                                            size: 18px;
                                            weight: 500;
                                            style: normal;
                                        }

                                        line-height: 1.2;
                                        color: #000000;
                                        margin-bottom: 0px;
                                    }
                                }

                                .translated-txt {
                                    margin: 31px 32px 38px 32px;
                                    flex-grow: 1;

                                    .text-area-form {
                                        height: auto;
                                        min-height: 120px;
                                        max-height: 100%;
                                        border: 0px;
                                        width: 100%;
                                        height: 100%;
                                        padding-right: 10px;
                                        resize: none;
        
                                        font: {
                                            size: 16px;
                                            weight: 500;
                                            style: normal;
                                        }

                                        line-height: 28px;
                                        color: #3C4043;
                                        margin-bottom: 0px;
        
                                        &::placeholder {
                                            font: {
                                                size: 16px;
                                                weight: normal;
                                                style: normal;
                                            }
        
                                            line-height: 1.5;
                                            color: #9B9FA2;
                                        }
        
                                        &:focus {
                                            outline: none;
                                        }
        
                                        &:hover::-webkit-scrollbar-thumb {
                                            opacity: 1;
                                            visibility: visible;
                                            transition: 0.3s all ease;
                                        }
        
                                        &::-webkit-scrollbar-track {
                                            border-radius: 30px;
                                            background-color: transparent;
                                            transition: 0.3s all ease;
                                        }
        
                                        &::-webkit-scrollbar {
                                            width: 5px;
                                            background-color: transparent;
                                            transition: 0.3s all ease;
                                        }
        
                                        &::-webkit-scrollbar-thumb {
                                            opacity: 0;
                                            visibility: hidden;
                                            border-radius: 30px;
                                            background-color: #C8C6C4;
                                            transition: 0.3s all ease;
                                        }
                                    }

                                    p {
                                        text-align: left;

                                        font: {
                                            size: 16px;
                                            weight: 500;
                                            style: normal;
                                        }

                                        line-height: 28px;
                                        color: #3C4043;
                                        margin-bottom: 0px;
                                    }
                                }
                            }
                        }

                        .publish-button-row {
                            text-align: center;
                            padding-top: 43px;

                            .translate-btn {
                                background-color: #0078D4;
                                padding: 10px 30px;

                                font: {
                                    size: 15px;
                                    weight: 500;
                                    style: normal;
                                }

                                line-height: 20px;
                                color: #ffffff;
                                border-radius: 2px;

                                &:disabled{
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ner-keyword-wrapper{
        padding-top: 70px;
        width: 842px;
        max-width: 100%;
        margin: auto;
        .ner-keyword-area{
            width: 100%;
            h2.title{
                font: {
                    size: 18px;
                    weight: 600;
                    style: normal;
                }
                line-height: 1.5;
                color: #000000;
                margin-bottom: 14px;
            }
            .analysed-wrapper{
                padding: 20px 28px;
                box-shadow: 0px 1px 1px #b1b9c5;
                border: 1px solid #DCE0E6;
                border-radius: 5px;
                background-color: #ffffff;
                min-height: 250px;
                max-height: 100%;
                height: auto;
                ul.analysed-tab-wrap{
                    margin-bottom: 0px;
                    padding-left: 0px;
                    display: flex;
                    align-items: center;
                    gap: 40px;
                    margin-bottom: 25px;
                    li.analysed-tab-link{
                        font: {
                            size: 16px;
                            family: "Roboto";
                            weight: 500;
                            style: normal;
                        }
                        line-height: 1.5;
                        color: #000000;
                        cursor: pointer;
                        transition: 0.3s all ease;
                        &::after{
                            content: "";
                            display: block;
                            margin-top: 6px;
                            height: 3px;
                            width: 100%;
                            background-color: transparent;
                            transition: 0.3s all ease;
                        }
                        &.active{
                            color: #0078D4;
                            position: relative;
                            &::after{
                                background-color: #0078D4;
                                transition: 0.3s all ease;
                            }
                        }
                    }
                }
                .tab-switch-wrapper{
                    .description-wrapper{
                        .textarea-form{
                            border: none;
                            width: 100%;
                            height: 200px;
                            overflow-y: auto;
                            font: {
                                size: 18px;
                                weight: normal;
                                family: "Roboto";
                                style: normal;
                            }
                            line-height: 1.5;
                            color: #3C4043;
                            transition: 0.3s all ease;
                            resize: none;
                            &:focus{
                                outline: none;
                                transition: 0.3s all ease;
                            }
                            &::placeholder{
                                font: {
                                    size: 16px;
                                    weight: normal;
                                    style: normal;
                                }
                                line-height: 1.5;
                                color: #9B9FA2;
                            }
                            .highlight{
                                padding: 0;
                                color: #3C4043;
                                background: #b5e5ff;
                            }
                        }
                        .btn-row{
                            text-align: right;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            flex-direction: row;
                            .translate-btn{
                                background-color: #0078D4;
                                padding: 10px 32px;
                                font: {
                                    size: 15px;
                                    weight: 500;
                                    style: normal;
                                }
                                line-height: 20px;
                                color: #ffffff;
                                border-radius: 2px;
                                &:disabled{
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
            }
            .btn-row{
                padding-top: 35px;
                display: flex;
                align-items: center;
                flex-direction: column;
                .download-btn{
                    background-color: #0078D4;
                    margin: 25px auto auto auto;
                    padding: 10px 23px;
                    &:disabled{
                        opacity: 0.5;
                    }
                    font: {
                        size: 15px;
                        weight: 500;
                        style: normal;
                    }
                    line-height: 20px;
                    color: #ffffff;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 7px;
                    .icon{
                        font: {
                            size: 20px;
                        }
                        color: #ffffff;
                    }
                }
            }
        }
    }
    .profile-gen-wrapper{
        padding-top: 10px;
        width: 1100px;
        max-width: 100%;
        margin: auto;
        .profile-gen-area{
            width: 100%;
            h2.title{
                font: {
                    size: 18px;
                    weight: 600;
                    style: normal;
                }
                line-height: 1.5;
                color: #000000;
                margin-bottom: 14px;
            }
            .profile-form-wrapper{
                padding: 20px 28px;
                box-shadow: 0px 1px 1px #b1b9c5;
                border: 1px solid #DCE0E6;
                border-radius: 5px;
                background-color: #ffffff;
                min-height: 250px;
                max-height: 100%;
                height: auto;
                .form-wrapper-row{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 10px 20px;
                    width: 100%;
                    .form-item-wrapper{
                        width: 49%;
                        max-width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 15px;
                        position: relative;
                        label{
                            font: {
                                size: 18px;
                                weight: 500;
                                style: normal;
                            }
                            line-height: 1.5;
                            color: #000000;
                            text-decoration: none;
                            margin-bottom: 5px;
                        }
                        input{
                            border-style: solid;
                            border-width: 2px 2px 2px 2px;
                            border-color: #C0C8CE;
                            border-radius: 3px;
                            width: 100%;
                            height: 50px;
                            padding: 15px 21px;
                            font: {
                                size: 16px;
                                weight: normal;
                                style: normal;
                            }
                            line-height: 1;
                            color: #3C4043;
                            transition: 0.3s all ease;
                            &:focus{
                                border-color: #0078D4;
                                transition: 0.3s all ease;
                            }
                            &::placeholder{
                                font: {
                                    size: 16px;
                                    weight: normal;
                                    style: normal;
                                }
                                line-height: 1.5;
                                color: #9B9FA2;
                            }
                        }
                        textarea{
                            border-style: solid;
                            border-width: 2px 2px 2px 2px;
                            border-color: #C0C8CE;
                            border-radius: 3px;
                            width: 100%;
                            height: 170px;
                            padding: 15px 21px;
                            font: {
                                size: 16px;
                                weight: normal;
                                style: normal;
                            }
                            line-height: 1.5;
                            color: #3C4043;
                            transition: 0.3s all ease;
                            resize: none;
                            &:focus{
                                outline: none;
                                border-color: #0078D4;
                                transition: 0.3s all ease;
                            }
                            &::placeholder{
                                font: {
                                    size: 16px;
                                    weight: normal;
                                    style: normal;
                                }
                                line-height: 1.5;
                                color: #9B9FA2;
                            }
                        }
                        small{
                            position: absolute;
                            bottom: -21px;
                        }
                    }
                }
            }
            .btn-row{
                padding-top: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 15px;
                .translate-btn{
                    background-color: #0078D4;
                    padding: 10px 32px;
                    font: {
                        size: 15px;
                        weight: 500;
                        style: normal;
                    }
                    line-height: 20px;
                    color: #ffffff;
                    border-radius: 2px;
                }
            }
        }
        .social-media-translated-txt-area {
            margin-top: 55px;

            h3 {
                font: {
                    size: 20px;
                    weight: bold;
                    style: normal;
                }

                color: #000000;
                text-decoration: none;
                margin-bottom: 18px;
            }

            .translated-area-row {
                display: flex;
                flex-wrap: wrap;
                gap: 30px;

                .translated-area-box {
                    width: 48.63%;
                    height: auto;
                    max-width: 100%;
                    box-shadow: 0px 1px 1px #B1B9C5;
                    border: 1px solid #DCE0E6;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    transition: 0.3s all ease;
                    position: relative;

                    .header {
                        padding: 22px 36px 16px 36px;
                        border-bottom: 1px solid #DCE0E6;
                        height: 60px;

                        p {
                            text-align: left;

                            font: {
                                size: 18px;
                                weight: 500;
                                style: normal;
                            }

                            line-height: 1.2;
                            color: #000000;
                            margin-bottom: 0px;
                        }
                    }

                    .translated-txt {
                        margin: 20px 20px 70px 20px;
                        flex-grow: 1;

                        .text-area-form {
                            height: auto;
                            max-height: 180px;
                            border: 0px;
                            width: 100%;
                            padding-right: 10px;
                            resize: none;
                            overflow: auto;

                            font: {
                                size: 16px;
                                weight: 500;
                                style: normal;
                            }

                            line-height: 28px;
                            color: #3C4043;
                            margin-bottom: 0px;

                            &::placeholder {
                                font: {
                                    size: 16px;
                                    weight: normal;
                                    style: normal;
                                }

                                line-height: 1.5;
                                color: #9B9FA2;
                            }

                            &:focus {
                                outline: none;
                            }

                            &:hover::-webkit-scrollbar-thumb {
                                opacity: 1;
                                visibility: visible;
                                transition: 0.3s all ease;
                            }

                            &::-webkit-scrollbar-track {
                                border-radius: 30px;
                                background-color: transparent;
                                transition: 0.3s all ease;
                            }

                            &::-webkit-scrollbar {
                                width: 5px;
                                background-color: transparent;
                                transition: 0.3s all ease;
                            }

                            &::-webkit-scrollbar-thumb {
                                opacity: 0;
                                visibility: hidden;
                                border-radius: 30px;
                                background-color: #C8C6C4;
                                transition: 0.3s all ease;
                            }
                        }

                        p {
                            text-align: left;

                            font: {
                                size: 16px;
                                weight: 500;
                                style: normal;
                            }

                            line-height: 28px;
                            color: #3C4043;
                            margin-bottom: 0px;
                        }

                    }
                    .btn-row{
                        text-align: right;
                        position: absolute;
                        bottom: 20px;
                        right: 20px;
                        .translate-btn{
                            border-radius: 50%;
                            width: 40px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: auto;
                            .icon{
                                font-size: 20px;
                                color: #3C4043;
                            }
                            &:hover{
                                background-color: #9b9fa259;
                            }
                        }
                    }
                }
            }

            .publish-button-row {
                text-align: center;
                padding-top: 43px;

                .translate-btn {
                    background-color: #0078D4;
                    padding: 10px 30px;

                    font: {
                        size: 15px;
                        weight: 500;
                        style: normal;
                    }

                    line-height: 20px;
                    color: #ffffff;
                    border-radius: 2px;

                    &:disabled{
                        opacity: 0.5;
                    }
                }
            }
        }
    }

}

// Social Media Localization Styles

// Subtitle Localization Styles

.sublocal-section-wrapper{
    .sublocal-wrap{
        padding: 60px;
        h1{
            font: {
                size: 34px;
                weight: bold;
                style: normal;
            }
            color: #000000;
            text-decoration: none;
            margin-bottom: 24px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .back-icon{
                position: absolute;
                left: -60px;
                top: -4px;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: 0.3s all ease;
                &:hover{
                    background-color: #ebebeb;
                    cursor: pointer;
                    transition: 0.3s all ease;
                }
                .arrow-icon{
                    font-size: 23px;
                    color: #000000;
                }
            }
        }
        p{
            font: {
                size: 16px;
                weight: normal;
                style: normal;
            }
            line-height: 1.7;
            color: #4E4E4E;
            text-decoration: none;
            margin-bottom: 0px;
            text-align: center;
        }
        .subtitle-localization-wrapper{
            padding-top: 40px;
            width: 842px;
            max-width: 100%;
            margin: auto;
            .subtitle-url-area{
                width: 100%;
                h2.title{
                    font: {
                        size: 18px;
                        weight: 600;
                        style: normal;
                    }
                    line-height: 1.5;
                    color: #000000;
                    margin-bottom: 14px;
                }
                .youtube-link-form{
                    border-style: solid;
                    border-width: 2px 2px 2px 2px;
                    border-color: #C0C8CE;
                    border-radius: 3px;
                    width: 100%;
                    height: 50px;
                    padding: 15px 21px;
                    font: {
                        size: 16px;
                        weight: normal;
                        style: normal;
                    }
                    line-height: 1.5;
                    color: #3C4043;
                    transition: 0.3s all ease;
                    &:focus{
                        border-color: #0078D4;
                        transition: 0.3s all ease;
                    }
                    &::placeholder{
                        font: {
                            size: 16px;
                            weight: normal;
                            style: normal;
                        }
                        line-height: 1.5;
                        color: #9B9FA2;
                    }
                }
            }
            .youtube-video-wrap{
                margin-top: 40px;
                padding: 30px;
                box-shadow: 0px 1px 1px #B1B9C5;
                border: 1px solid #DCE0E6;
                border-radius: 5px;

                .youtube-wrap{
                    border-radius: 5px;
                }
            }
            .lang-select-row{
                display: flex;
                align-items: center;
                gap: 30px;
                margin-top: 50px;
                .select-lang{
                    width: 50%;
                    max-width: 100%;
                    label{
                        font: {
                            size: 18px;
                            weight: 600;
                            style: normal;
                        }
                        line-height: 1.5;
                        color: #000000;
                        margin-bottom: 14px;
                    }
                    .select-lang-item{
                        height: 50px;
                        border-style: solid;
                        border-width: 2px 2px 2px 2px;
                        border-color: #C0C8CE;
                        border-radius: 3px;
                        width: 100%;
                        padding: 15px 12px 15px 21px;
                        justify-content: space-between;
                        span{
                            font: {
                                size: 18px;
                                weight: normal;
                                style: normal;
                            }
                            line-height: 1.5;
                            color: #3C4043;
                            &.placeholder-txt{
                                color: #9B9FA2 !important;
                            }
                        }
                    }
                }
            }
            .publish-button-row{
                text-align: center;
                padding-top: 43px;
                .translate-btn{
                    background-color: #0078D4;
                    padding: 10px 30px;
                    font: {
                        size: 15px;
                        weight: 500;
                        style: normal;
                    }
                    line-height: 20px;
                    color: #ffffff;
                    border-radius: 2px;

                    &:disabled{
                        opacity: 0.5;
                    }
                }
            }
        }

        .ai-writing-select-area{
            padding-top: 40px;
            width: 778px;
            max-width: 100%;
            margin: auto;
            h2.title{
                font: {
                    size: 18px;
                    weight: 600;
                    style: normal;
                }
                line-height: 1.5;
                color: #000000;
                margin-bottom: 14px;
            }
        }
        .ai-writing-wrapper{
            padding-top: 40px;
            width: 778px;
            max-width: 100%;
            margin: auto;
            .ai-choose-ai-prompt-wrapper{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 14px;
                .select-prompt__control{
                    width: 309px;
                }
            }
            .ai-writing-product-wrapper{
                padding-top: 62px;
                width: 100%;
                .ai-write-form-group{
                    width: inherit;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 40px;
                    label{
                        font: {
                            size: 18px;
                            weight: 500;
                            style: normal;
                        }
                        line-height: 1.5;
                        color: #000000;
                        text-decoration: none;
                        margin-bottom: 14px;
                    }
                    input{
                        border-style: solid;
                        border-width: 2px 2px 2px 2px;
                        border-color: #C0C8CE;
                        border-radius: 3px;
                        width: 100%;
                        height: 50px;
                        padding: 15px 21px;
                        font: {
                            size: 16px;
                            weight: normal;
                            style: normal;
                        }
                        line-height: 1;
                        color: #3C4043;
                        transition: 0.3s all ease;
                        &:focus{
                            border-color: #0078D4;
                            transition: 0.3s all ease;
                        }
                        &::placeholder{
                            font: {
                                size: 16px;
                                weight: normal;
                                style: normal;
                            }
                            line-height: 1.5;
                            color: #9B9FA2;
                        }
                    }
                    textarea{
                        border-style: solid;
                        border-width: 2px 2px 2px 2px;
                        border-color: #C0C8CE;
                        border-radius: 3px;
                        width: 100%;
                        height: 170px;
                        padding: 15px 21px;
                        font: {
                            size: 16px;
                            weight: normal;
                            style: normal;
                        }
                        line-height: 1.5;
                        color: #3C4043;
                        transition: 0.3s all ease;
                        resize: none;
                        &:focus{
                            outline: none;
                            border-color: #0078D4;
                            transition: 0.3s all ease;
                        }
                        &::placeholder{
                            font: {
                                size: 16px;
                                weight: normal;
                                style: normal;
                            }
                            line-height: 1.5;
                            color: #9B9FA2;
                        }
                    }
                }
                .btn-row{
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 15px;
                    .translate-btn{
                        background-color: #0078D4;
                        padding: 10px 32px;
                        font: {
                            size: 15px;
                            weight: 500;
                            style: normal;
                        }
                        line-height: 20px;
                        color: #ffffff;
                        border-radius: 2px;
                    }
                }
            }
            .ai-writing-generated-content{
                padding-top: 54px;
                .sub-text{
                    font: {
                        size: 18px;
                        weight: 500;
                        style: normal;
                    }
                    line-height: 1;
                    color: #000000;
                    margin-bottom: 14px;
                    text-align: left;
                }
                .generated-content-wrapper{
                    padding: 36px 32px;
                    box-shadow: 0px 1px 1px #B1B9C5;
                    border: 1px solid #DCE0E6;
                    border-radius: 5px;
                    background-color: #ffffff;
                    position: relative;
                    margin-bottom: 20px;
                    font: {
                        size: 18px;
                        weight: 400;
                        style: normal;
                    }
                    line-height: 28px;
                    color: #3C4043;
                    &:focus{
                        outline: none;
                    }
                    ul{
                        li{
                            font: {
                                size: 18px;
                                weight: 400;
                                style: normal;
                            }
                            line-height: 1.5;
                            color: #3C4043;
                            list-style-type: disc;
                            margin-bottom: 5px;
                        }
                    }
                    .btn-row{
                        text-align: right;
                        position: absolute;
                        bottom: 15px;
                        right: 15px;
                        padding-top: 0px;
                        .translate-btn{
                            border-radius: 50%;
                            width: 40px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: auto;
                            .icon{
                                font-size: 20px;
                                color: #3C4043;
                            }
                            &:hover{
                                background-color: #9b9fa259;
                            }
                        }
                    }
                }
                .btn-row{
                    padding-top: 35px;
                    .download-btn{
                        background-color: #0078D4;
                        margin: auto;
                        padding: 10px 23px;
                        font: {
                            size: 15px;
                            weight: 500;
                            style: normal;
                        }
                        line-height: 20px;
                        color: #ffffff;
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 7px;
                        .icon{
                            font: {
                                size: 20px;
                            }
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}

// Subtitle Localization Styles


// Source & Target Language Modal//

.ai-lang-select-modal {
    z-index: 1080 !important;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);

    &.ai-large-modal{
        .rodal-dialog{
            height: 600px !important;
        }
    }

    .rodal-dialog {
        border: none;
        box-shadow: 0px 3px 14px #00000038 !important;
        border-radius: 10px !important;
        height: 542px !important;
        padding: 0 !important;
        width: 100% !important;
        max-width: 85%;
    }

    .lang-modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 25px;
        border-bottom: 1px solid #e0e0e0;

        h1 {
            color: #3c4043;
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            font-family: "Roboto", sans-serif;
            margin-bottom: 0px;
        }

        .modal-close-btn {
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: transparent;
            transition: 0.4s all ease;
        }
    }

    .ai-source-language-cont {
        padding: 8px 25px 9px 25px;
        height: auto;
        position: relative;

        ul.ai-source-langs-list {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 450px;
            overflow: auto;
            padding-left: 0px;
            margin-bottom: 0px;
            list-style-type: none;

            &:hover::-webkit-scrollbar-thumb {
                opacity: 1;
                visibility: visible;
                transition: 0.3s all ease;
            }
    
            &::-webkit-scrollbar-track {
                border-radius: 30px;
                background-color: transparent;
                transition: 0.3s all ease;
            }
    
            &::-webkit-scrollbar {
                height: 6px;
                background-color: transparent;
                transition: 0.3s all ease;
            }
    
            &::-webkit-scrollbar-thumb {
                opacity: 0;
                visibility: hidden;
                border-radius: 30px;
                background-color: #C8C6C4;
                transition: 0.3s all ease;
            }

            li.list {
                padding: 5px;
                color: #3c4043;
                font-size: 14px;
                line-height: 24px;
                font-weight: normal;
                font-family: "Roboto", sans-serif;
                transition: 0.4s all ease;
                border-radius: 3px;
                margin-bottom: 0.4rem;
                margin-right: 0.4rem;

                &:hover {
                    background-color: #f1f1f1;
                    cursor: pointer;
                }

                &.selected {
                    background-color: #e8f0fe;

                    &:hover {
                        background-color: #e8f0fe;
                    }

                    img.checked-icon {
                        opacity: 1;
                    }
                }

                img.checked-icon {
                    opacity: 0;
                    transition: 0.4s all ease;
                    padding-right: 0.4rem;
                }
            }
        }

        .text-end {
            text-align: right;
            margin-top: 15px;
            .login-btn {
                color: #ffffff;
                font-size: 15px;
                line-height: 19px;
                font-weight: bold;
                padding: 10px 40px;
                background-color: #0078D4;
                border-radius: 2px;
            }
        }
    }
}
.ai-tar-lang-select-modal {
    z-index: 1080 !important;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);

    &.ai-large-modal{
        .rodal-dialog{
            height: 600px !important;
        }
    }

    .rodal-dialog {
        height: 542px !important;
        border: none;
        box-shadow: 0px 3px 14px #00000038 !important;
        border-radius: 10px !important;
        padding: 0 !important;
        width: 100% !important;
        max-width: 85%;
    }

    .lang-modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 25px;
        border-bottom: 1px solid #e0e0e0;

        h1 {
            color: #3c4043;
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            font-family: "Roboto", sans-serif;
            margin-bottom: 0px;
        }

        .modal-close-btn {
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: transparent;
            transition: 0.4s all ease;
        }
    }

    .ai-source-language-cont {
        padding: 8px 25px 9px 25px;
        height: auto;
        position: relative;

        ul.ai-source-langs-list {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 450px;
            padding-left: 0px;
            margin-bottom: 0px;
            list-style-type: none;
            overflow: auto;

            &:hover::-webkit-scrollbar-thumb {
                opacity: 1;
                visibility: visible;
                transition: 0.3s all ease;
            }
    
            &::-webkit-scrollbar-track {
                border-radius: 30px;
                background-color: transparent;
                transition: 0.3s all ease;
            }
    
            &::-webkit-scrollbar {
                height: 6px;
                background-color: transparent;
                transition: 0.3s all ease;
            }
    
            &::-webkit-scrollbar-thumb {
                opacity: 0;
                visibility: hidden;
                border-radius: 30px;
                background-color: #C8C6C4;
                transition: 0.3s all ease;
            }
            

            li.list {
                padding: 5px;
                color: #3c4043;
                font-size: 14px;
                line-height: 24px;
                font-weight: normal;
                font-family: "Roboto", sans-serif;
                transition: 0.4s all ease;
                border-radius: 3px;
                margin-bottom: 0.4rem;
                margin-right: 0.4rem;

                &:hover {
                    background-color: #f1f1f1;
                    cursor: pointer;
                }

                &.selected {
                    background-color: #e8f0fe;

                    &:hover {
                        background-color: #e8f0fe;
                    }

                    img.checked-icon {
                        opacity: 1;
                    }
                }

                img.checked-icon {
                    opacity: 0;
                    transition: 0.4s all ease;
                    padding-right: 0.4rem;
                }
            }
        }

        .text-end {
            text-align: right;
            margin-top: 15px;
            .login-btn {
                color: #ffffff;
                font-size: 15px;
                line-height: 19px;
                font-weight: bold;
                padding: 10px 40px;
                background-color: #0078D4;
                border-radius: 2px;
            }
        }
    }
}

// Source & Target Language Modal//

.select-lang__single-value{
    font: {
        size: 18px;
        family: "Inter";
        weight: 400;
        style: normal;
    }
    line-height: 1.5;
    color: #3c4043;
}

//Text to Speech Styles

.tts-section-wrapper {

    .tts-wrap {
        padding: 60px;

        h1 {
            font: {
                size: 34px;
                weight: bold;
                style: normal;
            }

            color: #000000;
            text-decoration: none;
            margin-bottom: 24px;
            text-align: center;
            position: relative;
            .back-icon{
                position: absolute;
                left: -60px;
                top: -4px;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: 0.3s all ease;
                &:hover{
                    background-color: #ebebeb;
                    cursor: pointer;
                    transition: 0.3s all ease;
                }
                .arrow-icon{
                    font-size: 23px;
                    color: #000000;
                }
            }
        }

        p {
            font: {
                size: 16px;
                weight: normal;
                style: normal;
            }

            line-height: 1.7;
            color: #4E4E4E;
            text-decoration: none;
            margin-bottom: 0px;
            text-align: center;
        }

        .tts-section-wrap {

            padding: 68px 0 0 0;

            .ai-translation-textarea-wrapper1 {

                position: relative;
                padding: 10px 32px 21px 32px;
                box-shadow: 0px 1px 1px #B1B9C5;
                border: 1px solid #DCE0E6;
                border-radius: 5px;
                background-color: #ffffff;

                .editor {
                    height: auto;
                    min-height: 120px;
                    max-height: 120px;
                    overflow: auto;
                    border: 0px;
                    width: 100%;
                    padding-right: 10px;
                    resize: none;

                    font: {
                        size: 16px;
                        weight: normal;
                        style: normal;
                    }

                    line-height: 1.5;
                    color: #3C4043;

                    &::placeholder {
                        font: {
                            size: 16px;
                            weight: normal;
                            style: normal;
                        }

                        line-height: 1.5;
                        color: #9B9FA2;
                    }

                    &:focus {
                        outline: none;
                    }

                    &:hover::-webkit-scrollbar-thumb {
                        opacity: 1;
                        visibility: visible;
                        transition: 0.3s all ease;
                    }

                    &::-webkit-scrollbar-track {
                        border-radius: 30px;
                        background-color: transparent;
                        transition: 0.3s all ease;
                    }

                    &::-webkit-scrollbar {
                        width: 5px;
                        background-color: transparent;
                        transition: 0.3s all ease;
                    }

                    &::-webkit-scrollbar-thumb {
                        opacity: 0;
                        visibility: hidden;
                        border-radius: 30px;
                        background-color: #C8C6C4;
                        transition: 0.3s all ease;
                    }





                }

                .listen-button {
                    position: absolute;
                    bottom: 21px;
                    left: 462px;
                    background-color: transparent;
                    padding: 9px 13px;
                    display: flex;
                    align-items: center;
                    border-radius: 2px;
                    gap: 7px;
                    transition: 0.3s all ease;

                    .txt {
                        font: {
                            size: 15px;
                            weight: 500;
                            style: normal;
                        }

                        line-height: 20px;
                        color: #3C4043;
                    }

                    .icon {
                        color: #5F6368;
                    }

                    &:hover {
                        background-color: #EBEBEB;
                        transition: 0.3s all ease;
                        cursor: pointer;
                    }
                }

                .clear-div{

                    text-align: right;

                .clear-but{

                    border-radius: 50%;
                    padding: 5px;
                    transition: 0.3s all ease;
                    opacity: 1;
                    

                    .icon {
                        color: #5F6368;

                    }

                    &:hover {
                        background-color: #EBEBEB;
                        transition: 0.3s all ease;
                        cursor: pointer;
                    }
                    &:disabled{
                        opacity: 0;
                        transition: 0.3s all ease;

                    }

                }

            }

                .lang-select-wrap {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    .language-button {
                        background-color: transparent;
                        padding: 10px 13px 10px 13px;
                        display: flex;
                        align-items: center;
                        border-radius: 2px;
                        gap: 7px;
                        transition: 0.3s all ease;
                        border: none;

                        .txt {
                            font: {
                                size: 15px;
                                weight: 500;
                                style: normal;
                            }

                            line-height: 20px;
                            color: #3C4043;
                        }

                        .icon {
                            color: #5F6368;
                        }

                        &:hover {
                            background-color: #EBEBEB;
                            transition: 0.3s all ease;
                            cursor: pointer;
                        }

                        &:focus-visible {
                            outline: none;

                        }
                    }
                }

                .translate-button {
                    background-color: #0078D4;
                    padding: 10px 30px;

                    .txt {

                        font: {
                            size: 15px;
                            weight: 500;
                            style: normal;

                        }

                        color: #ffffff;
                        line-height: 20px;
                        border-radius: 2px;
                    }

                    &:disabled {
                        opacity: 0.5;
                    }
                }

            }

            .ai-translatiion-main-wrap{

                margin-top: 58px;

                h3 {
                    font: {
                        size: 20px;
                        weight: bold;
                        style: normal;
                    }

                    color: #000000;
                    text-decoration: none;
                    margin-bottom: 18px;
                }

                .ai-translation-textarea-wrapper2 {
                    padding: 36px 32px 21px 32px;
                    box-shadow: 0px 1px 1px #B1B9C5;
                    border: 1px solid #DCE0E6;
                    border-radius: 5px;
                    background-color: #ffffff;

                    .ai-translation-textarea {
                        height: auto;
                        min-height: 120px;
                        max-height: 100%;
                        border: 0px;
                        width: 100%;
                        padding-right: 10px;
                        resize: none;

                        font: {
                            size: 16px;
                            weight: normal;
                            style: normal;
                        }

                        line-height: 1.5;
                        color: #3C4043;

                        &::placeholder {
                            font: {
                                size: 16px;
                                weight: normal;
                                style: normal;
                            }

                            line-height: 1.5;
                            color: #9B9FA2;
                        }

                        &:focus {
                            outline: none;
                        }

                        &:hover::-webkit-scrollbar-thumb {
                            opacity: 1;
                            visibility: visible;
                            transition: 0.3s all ease;
                        }

                        &::-webkit-scrollbar-track {
                            border-radius: 30px;
                            background-color: transparent;
                            transition: 0.3s all ease;
                        }

                        &::-webkit-scrollbar {
                            width: 5px;
                            background-color: transparent;
                            transition: 0.3s all ease;
                        }

                        &::-webkit-scrollbar-thumb {
                            opacity: 0;
                            visibility: hidden;
                            border-radius: 30px;
                            background-color: #C8C6C4;
                            transition: 0.3s all ease;
                        }
                    }

                    .speech-down-wrap {

                        display: flex;
                        align-items: center;
                        gap: 15px;
                        justify-content: space-between;

                        .speech-button {
                            background-color: transparent;
                            padding: 10px 7px 10px 13px;
                            display: flex;
                            align-items: center;
                            border-radius: 2px;
                            gap: 7px;
                            transition: 0.3s all ease;

                            .txt {
                                font: {
                                    size: 15px;
                                    weight: 500;
                                    style: normal;
                                }

                                line-height: 20px;
                                color: #3C4043;
                            }

                            .icon {
                                color: #5F6368;
                            }

                            &:hover {
                                background-color: #EBEBEB;
                                transition: 0.3s all ease;
                                cursor: pointer;
                            }



                            &:disabled {
                                visibility: hidden;
                            }

                        }

                        .download-button {
                            background-color: #0078D4;
                            padding: 10px 20px;
                            display: flex;
                            align-items: center;
                            border-radius: 2px;
                            gap: 7px;
                            transition: 0.3s all ease;

                            .txt {
                                font: {
                                    size: 15px;
                                    weight: 500;
                                    style: normal;
                                }

                                line-height: 20px;
                                color: #FFFFFF;
                            }

                            .icon {
                                color: #FFFFFF;
                            }

                            &:disabled {
                                opacity: 0.5;
                            }

                        }

                    }

                    .display {
                        display: none;
                    }

                }
            }
        }

    }

}

.spl-section-wrapper {

    .spl-wrap-header {
        padding-top: 40px;

        h1 {
            font: {
                size: 34px;
                weight: bold;
                style: normal;
            }

            color: #000000;
            text-decoration: none;
            margin-bottom: 30px;
            text-align: center;
            position: relative;
            .back-icon{
                position: absolute;
                left: -60px;
                top: -4px;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: 0.3s all ease;
                &:hover{
                    background-color: #ebebeb;
                    cursor: pointer;
                    transition: 0.3s all ease;
                }
                .arrow-icon{
                    font-size: 23px;
                    color: #000000;
                }
            }
        }

    }
    .spl-wrap {
        padding:  20px 40px 20px 40px;

        p {
            font: {
                size: 16px;
                weight: normal;
                style: normal;
            }

            line-height: 1.7;
            color: #4E4E4E;
            text-decoration: none;
            margin-bottom: 0px;
            text-align: center;
        }

        .spl-section-wrap {
            padding: 0;

            .spl-templates-wrapper{

                .h5{
                    text-align: center;
                }

                .size-img{

                    width: 100%;
                    height: 100%;
                    transition: 0.3s ease all;
                  
                }
                  
                .size-img:hover{
                
                transform: scale(1.1);
                transition: 0.3s ease all;
                cursor: pointer;
                }

            }

            .canvas-toolbar-wrapper {

                gap: 12px;


                .camvas-language-button {
                    background-color: transparent;
                    padding: 10px 13px 10px 13px;
                    display: flex;
                    align-items: center;
                    border-radius: 2px;
                    gap: 7px;
                    transition: 0.3s all ease;
                    border: none;

                    .txt {
                        font: {
                            size: 15px;
                            weight: 500;
                            style: normal;
                        }

                        line-height: 20px;
                        color: #3C4043;
                    }

                    .icon {
                        color: #5F6368;
                    }

                    &:hover {
                        background-color: #EBEBEB;
                        transition: 0.3s all ease;
                        cursor: pointer;
                    }

                    &:focus-visible {
                        outline: none;

                    }
                }

                .canvas-translate-button {
                    background-color: #0078D4;
                    padding: 10px 30px;

                    .txt {

                        font: {
                            size: 15px;
                            weight: 500;
                            style: normal;

                        }

                        color: #ffffff;
                        line-height: 20px;
                        border-radius: 2px;
                    }

                    &:disabled {
                        opacity: 0.5;
                    }
                }
            }

            .canvas {
                background-color: #FFFFFF;
                position: relative;
                height: 600px;
                width: 800px;
                margin: 0 auto;
                box-shadow: 0px 1px 1px #b1b9c5;
                border: 1px solid #DCE0E6;
                border-radius: 5px;
            }

            .canvas-modal-wrap {

                text-align: center;

                .canvas-Preview-button {

                    margin-top: 10px;
                    background-color: #0078D4;
                    padding: 10px 30px;

                    .txt {

                        font: {
                            size: 15px;
                            weight: 500;
                            style: normal;

                        }

                        color: #ffffff;
                        line-height: 20px;
                        border-radius: 2px;
                    }

                    &:disabled {
                        opacity: 0.5;
                    }

                   
                    

                }

                
               
                

            }

        }

       


    }
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;
    z-index: 1;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0px);
    transition: all 500ms ease-in-out;

  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;

  }


    // Loader effect starts //
.animation-logo {
    position: fixed;
    background: #ffffffd1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1080;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-logo {
        width: 81px;
        height: 61px;
        background-image: url(../loaders/gradient-img.jpg);
        -webkit-mask: url(../loaders/svg-logo-animation.svg) center/cover;
        mask: url(../loaders/svg-logo-animation.svg) center/cover;
        -webkit-background-clip: content-box;
        background-clip: content-box;
        background-size: 200% 200%;
        animation: gradient-animation 5s ease-in-out infinite;
    }
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 100%;
    }
    50% {
        background-position: 180% 0%;
    }
    100% {
        background-position: 0% 150%;
    }
}
// Loader effect ends //


//Button Loader Effect//

.save-btn-spinner {
    display: inline-block;
    position: relative;
    width: 10px;
    height: 10px;
    left: -40px;
    top: -5px;

    div {
        transform-origin: 40px 11px;
        animation: save-btn-spinner 1.2s linear infinite;
    }

    div:after {
        content: " ";
        display: block;
        position: absolute;
        top: 3px;
        left: 37px;
        width: 2px;
        height: 4px;
        border-radius: 45%;
        background: #fff;
    }
    div:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
    }
    div:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
    }
    div:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
    }
    div:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
    }
    div:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
    }
    div:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
    }
    div:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
    }
    div:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
    }
    div:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
    }
    div:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
    }
    div:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
    }
    div:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
    }
}


@keyframes save-btn-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

//Button Loader Effect//


.ai-mark-confirm-box {
    z-index: 1080 !important;

    .rodal-dialog {
        border: none;
        box-shadow: 0px 3px 14px #00000038 !important;
        border-radius: 6px !important;
        height: 300px !important;
        padding: 0 !important;
        width: 450px !important;
        max-width: 100%;

        .proposal-modal-header {
            position: relative;

            .modal-close-btn {
                position: absolute;
                right: 19px;
                top: 11px;
                height: 25px;
                width: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: transparent;
                transition: 0.3s all ease;
                &:hover {
                    background-color: var(--icon-hover-bg);
                    cursor: pointer;
                    transition: 0.3s all ease;
                }
            }
        }

        .confirmation-wrapper{
            padding: 20px 35px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;

            h2{
                font-family: Roboto; 
                font-style: normal;
                font-weight: 500;
                line-height: 1.5;
                font-size: 18px;
                color: #3C4043;
                margin-bottom: 0px;
                text-align: center;
            }

            a{
                font-family: Roboto; 
                font-style: normal;
                font-weight: 400;
                line-height: 1.5;
                font-size: 16px;
                color: #0078D4;
                margin-bottom: 0px;
                text-align: center;
            }

            .button-row{
                display: flex;
                align-items: center;
                gap: 12px;
                margin-top: 12px;

                .cancel-txt {
                    font: {
                        size: 14px;
                        weight: 500;
                        style: normal;
                    }
                    color: #ffffff;
                    line-height: 1.4;
                    padding: 10px 26.5px;
                }

                .submit-txt {
                    font: {
                        size: 15px;
                        weight: 500;
                        style: normal;
                    }
                    color: #ffffff;
                    line-height: 1.4;
                    padding: 6px 36px;
                    background-color:#0078D4;
                }
            }
        }
    }
}


.convert-section-wrapper {

    .convert-wrap-header {
        padding-top: 40px;

        h1 {
            font: {
                size: 34px;
                weight: bold;
                style: normal;
            }

            color: #000000;
            text-decoration: none;
            margin-bottom: 30px;
            text-align: center;
            position: relative;
            .back-icon{
                position: absolute;
                left: -60px;
                top: -4px;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: 0.3s all ease;
                &:hover{
                    background-color: #ebebeb;
                    cursor: pointer;
                    transition: 0.3s all ease;
                }
                .arrow-icon{
                    font-size: 23px;
                    color: #000000;
                }
            }
        }

    }
    .convert-wrap {
        // padding:  20px 40px 20px 40px;
        border: 1px solid #ebebeb;
        border-radius: 4px

        p {
            font: {
                size: 16px;
                weight: normal;
                style: normal;
            }

            line-height: 1.7;
            color: #4E4E4E;
            text-decoration: none;
            margin-bottom: 0px;
            text-align: center;
        }

        .convert-inner-main-wrapper{
            background: #fff 0 0 no-repeat padding-box;
            border-right: 1px solid #dfdfdf;
            height: inherit;
            opacity: 1;
            height: auto;
            padding: 20px 10px;
            // padding-top: 40px;
            // width: 600px;
            // max-width: 100%;
            // margin: auto;
            .upload-file{
                width: 100%;
                h2.title{
                    font: {
                        size: 16px;
                        weight: 600;
                        style: normal;
                    }
                    line-height: 1.5;
                    color: #000000;
                    margin-bottom: 6px;
                }
                .upload-div{

                    .upload{
                        width: 100%;
                        padding: 12px;
                        background: #0078D4 !important;
                        border-radius: 3px !important;
                        opacity: 1 !important;
                        padding: 8px 15px !important;
                        color: #FFFFFF !important;
                        text-align: center;
                        font-weight: normal;
                        font-style: medium;
                        font-size: 16px !important;
                        font-family: 'Roboto';
                        letter-spacing: 0px;
                    }
                }

            }

                .lang-select-row{
                    // display: flex;
                    // align-items: center;
                    // gap: 30px;
                    margin-top: 10px;
                    .select-lang{
                        width: 100%;
                        // max-width: 100%;
                        margin-top: 10px;

                        label{
                            font: {
                                size: 16px;
                                weight: 600;
                                style: normal;
                            }
                            line-height: 1.5;
                            color: #000000;
                            margin-bottom: 6px;
                        }
                        .select-lang-item{
                            height: 50px;
                            border-style: solid;
                            border-width: 2px 2px 2px 2px;
                            border-color: #C0C8CE;
                            border-radius: 3px;
                            width: 100%;
                            padding: 15px 12px 15px 21px;
                            justify-content: space-between;
                            span{
                                font: {
                                    size: 18px;
                                    weight: normal;
                                    style: normal;
                                }
                                line-height: 1.5;
                                color: #3C4043;
                                &.placeholder-txt{
                                    color: #9B9FA2 !important;
                                }
                            }
                        }
                    }
                }

                .convert-download-file{
                    width: 50%;
                    margin: 0 auto;
                    margin-top: 20px;
                    .convert-download-div{

                        .convert-down-a{
                            text-decoration: none;
                            .convert-download{
                                width: 100%;
                                padding: 12px;
                                background: #0078D4 !important;
                                border-radius: 3px !important;
                                opacity: 1 !important;
                                padding: 8px 15px !important;
                                color: #FFFFFF !important;
                                text-align: center;
                                font-weight: normal;
                                font-style: medium;
                                font-size: 16px !important;
                                font-family: 'Roboto';
                                letter-spacing: 0px;
                                
                                &:disabled{
                                    opacity: 0.5 !important;
                                }
                                    
                            }
                            

                        }
                        
                    }
    
                }

                

        }

        .conver-files{
            background: #f1f1f1 0 0 no-repeat padding-box;

            .refresh-container{
                padding: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .refresh-icon{
                    padding: 3px;
                    background-color: #FFFFFF;
                    border-radius: 50%;
                    box-shadow: 0 1px 4px #0000001a;
                    cursor: pointer;
                    transition: all .3s ease;

                    &:hover{
                        background: #eff4f6 0 0 no-repeat padding-box;
                        transition: all .3s ease;
                    }

                    &:active{
                        background: #e7ecec 0 0 no-repeat padding-box;
                        transition: all .3s ease;
                    }
                }
                .heading-file-list{
                    margin: 0;
                    color: #0078D4;
                    font-weight: bold;
                    font-size: 20px;
                    font-style: normal;
                    font-family: 'Roboto';
                }
            }

            .each-file-row-head{
                opacity: 1;
                justify-content: center;
                align-items: center;

                .sn-no{
                    text-align: center;
                    border-top: 1px solid #302a2a86;
                    border-bottom: 1px solid #302a2a86;
                    height: 40px;
                    padding: 5px;
                    font-weight: bold;
                    font-size: 18px;
                    font-style: normal;
                    font-family: 'Roboto';
                    


                }

                .pdf-name{
                    
                    border-top: 1px solid #302a2a86;
                    border-bottom: 1px solid #302a2a86;
                    height: 40px;
                    padding: 5px;

                    font-weight: bold;
                    font-size: 18px;
                    font-style: normal;
                    font-family: 'Roboto';
                    


                }

                .pdf-total-page{
                    text-align: center;
                    border-top: 1px solid #302a2a86;
                    border-bottom: 1px solid #302a2a86;
                    height: 40px;
                    padding: 5px;

                    font-weight: bold;
                    font-size: 18px;
                    font-style: normal;
                    font-family: 'Roboto';

                }

                .pdf-translated-percentage{
                    text-align: center;
                    border-top: 1px solid #302a2a86;
                    border-bottom: 1px solid #302a2a86;
                    height: 40px;
                    padding: 5px;

                    font-weight: bold;
                    font-size: 18px;
                    font-style: normal;
                    font-family: 'Roboto';

                }

                .download-each{

                    border-top: 1px solid #302a2a86;
                    border-bottom: 1px solid #302a2a86;
                    padding: 5px;
                    text-align: center;
                    height: 40px;
                    font-weight: bold;
                    font-size: 18px;
                    font-style: normal;
                    font-family: 'Roboto';
                }


            }

            .converted-files-inner-wrap{
                overflow-y: scroll;
                overflow-x: hidden;
                height: 70vh;
                scroll-behavior: smooth;

                &:hover::-webkit-scrollbar-thumb {
                    opacity: 1;
                    visibility: visible;
                    transition: 0.3s all ease;
                }
    
                &::-webkit-scrollbar-track {
                    border-radius: 30px;
                    background-color: transparent;
                    transition: 0.3s all ease;
                }
    
                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: transparent;
                    transition: 0.3s all ease;
                }
    
                &::-webkit-scrollbar-thumb {
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 30px;
                    background-color: #C8C6C4;
                    transition: 0.3s all ease;
                }

                

                .each-file-row{
                    background-color: #fff;
                    border-radius: 4px;
                    box-shadow: 0 1px 4px #0000001a;
                    opacity: 1;
                    justify-content: center;
                    align-items: center;

                    .sn-no{
                        text-align: center;
                        border-right: 1px solid #dfdfdf;
                        border-bottom: 1px solid #dfdfdf;
                        height: 40px;
                        padding: 5px;
                        .name-sn{
                            font-weight: bold;
                            font-size: 15px;
                            font-style: normal;
                            font-family: 'Roboto';
                        }
                        


                    }

                    .pdf-name{
                        
                        border-right: 1px solid #dfdfdf;
                        border-bottom: 1px solid #dfdfdf;
                        height: 40px;
                        padding: 5px;

                        .name-pdf{
                            font-weight: normal;
                            font-size: 14px;
                            font-style: normal;
                            font-family: 'Roboto';

                        }
                        


                    }

                    .pdf-total-page{
                        text-align: center;
                        border-right: 1px solid #dfdfdf;
                        border-bottom: 1px solid #dfdfdf;
                        height: 40px;
                        padding: 5px;

                        .name-page{
                            font-weight: bolder;
                            font-size: 13px;
                            font-style: normal;
                            font-family: 'Roboto';
                        }

                    }

                    .pdf-translated-percentage{
                        text-align: center;
                        border-right: 1px solid #dfdfdf;
                        border-bottom: 1px solid #dfdfdf;
                        height: 40px;
                        padding: 5px;

                        .name-status{

                            font-weight: bolder;
                            color: #0078D4;
                            font-size: 13px;
                            font-style: normal;
                            font-family: 'Roboto';
                        }

                    }

                    .download-each{

                        border-right: 1px solid #dfdfdf;
                        border-bottom: 1px solid #dfdfdf;
                        padding: 5px;
                        text-align: center;
                        display: flex;
                        gap: 10px;
                        justify-content: center;
                        align-items: center;
                        height: 40px;
                        .convert-file-download{

                            // padding: 12px;
                            background: #0078D4 !important;
                            border-radius: 3px !important;
                            opacity: 1 !important;
                            padding: 6px !important;
                            

                            .inner-a{
                                font-weight: normal;
                            font-style: medium;
                            font-size: 10px !important;
                            font-family: 'Roboto' !important;
                            letter-spacing: 0px;
                            color: #FFFFFF !important;
                            text-align: center;
                                text-decoration: none;

                            }

                            
                        }

                        .convert-file-download2{

                            // padding: 12px;
                            // background: #d9534f !important;
                            border-radius: 3px !important;
                            opacity: 1 !important;
                            padding: 6px 10px !important;
                            

                            .inner-a2{
                                font-weight: normal;
                            font-style: medium;
                            font-size: 10px !important;
                            font-family: 'Roboto' !important;
                            letter-spacing: 0px;
                            color: #FFFFFF !important;
                            text-align: center;
                                text-decoration: none;

                            

                            }

                           
                            
                        }

                        .convert-file-download-delete{

                            // padding: 12px;
                            background: #c70000 !important;
                            border-radius: 3px !important;
                            opacity: 1 !important;
                            padding: 6px !important;
                            

                            .inner-a-delete{
                                font-weight: normal;
                            font-style: medium;
                            font-size: 10px !important;
                            font-family: 'Roboto' !important;
                            letter-spacing: 0px;
                            color: #FFFFFF !important;
                            text-align: center;
                                text-decoration: none;

                            }

                            
                        }

                    }


                }
            }

            
        }

    }

}

.shool-background .lab-main-navbar{
    border-bottom: none;
    background-color: transparent;
  }
  
  .shool-background .lab-main-navbar .container{
    max-width: 95%;
   
    margin: 0 auto;
    border-radius: 0 0 12px 12px;
    border-image:  linear-gradient(105deg, rgba(118, 255, 255, 0.40) 3.09%, rgba(118, 255, 255, 0.00) 73.27%);
    border-width: 1px;
    border-style: solid;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
  
  }