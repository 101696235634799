.upoad-image-details {
    border: 1px solid #e7e9eb!important;
    padding: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.content-container{
    width: calc(100% - 260px);
}

.image-container{
    width: 250px;
    height: 250px;
}   

.image-container img{
    height: 100%;
    width: 100%;
}

.split-bar{
    width: 1px;
    border: 1px solid #000;
    height: 250px;
}

.save-main-btn{
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.5;
    color: #ffffff;
    text-decoration: none;
    margin-bottom: 0px;
    padding: 10px 22px;
    background-color: #0078D4;
    border-radius: 2px;
}

.active-search-bar {
    background-color:#fff;
    outline: 1px solid #0071D4;
    /* box-shadow: 0 1px 1px 0 rgb(65 69 73 / 30%), 0 1px 3px 1px rgb(65 69 73 / 15%); */
    font-family: 'Roboto'
    }
    
    .search-placeholder-text {
    width:100%
    }
    
    .main-tab-search {
        display:flex;
        align-items:center;
        padding:12px;
        border-radius:4px;
        opacity:1;
        width:100%;
        background-color:#F0F2F3;
        gap:8px;
        padding:7.5px 10.5px
        }

        .searcher {
            background-color: inherit;
            border: none;
            color: #74818d;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            height: 100%;
            outline: none;
            padding: 5px;
            width: 100%;
        }